import axios from 'axios';
import Api from './Api';

class ThreeDSecure extends Api {
  async confirm3d(id, caRes) {
    return (await this.client.post(`/transactions/${id}/3d-secure`, { caRes })).data;
  }

  async confirm3dFallback(id, paRes) {
    return (await this.client.post(`/transactions/${id}/3d-secure`, { paRes })).data;
  }

  async submit3dChallenge({ acsUrl, creq, threeDSSessionData }) {
    return (await axios.post(acsUrl, { creq, threeDSSessionData })).data;
  }
}

export default ThreeDSecure;
