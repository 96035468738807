import Session from './merchantSession/Session';
import Api from './Api';

class MerchantSession extends Api {
  async getSession() {
    if (!this.session || this.session.isExpired()) {
      this.session = await this.refreshSession();
    }
    return this.session;
  }

  async refreshSession() {
    const result = await this.client.post('/merchant-sessions');
    return new Session(result.data);
  }
}

export default MerchantSession;
