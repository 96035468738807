<script>
import * as paymentMutations from '@/store/payment/mutations';
import { mapGetters, mapMutations } from 'vuex';
import AddressLookup from '@/components/forms/inputGroups/addressLookup/AddressLookup';

export default {
  name: 'ClientDetails',
  components: { AddressLookup },
  data() {
    return {
      triggerValidation: false,
    };
  },
  computed: {
    ...mapGetters({ currentCase: 'paymentDetails/case' }),
    customerFirstName: {
      get() {
        return this.currentCase.customerFirstName;
      },
      set(value) {
        this[`${paymentMutations.PAYMENTS_SET_CUSTOMER_FIRST_NAME}`](value);
      },
    },
    customerLastName: {
      get() {
        return this.currentCase.customerLastName;
      },
      set(value) {
        this[`${paymentMutations.PAYMENTS_SET_CUSTOMER_LAST_NAME}`](value);
      },
    },
    billingAddress: {
      get() {
        return this.currentCase.billingAddress;
      },
      set(address) {
        Object.entries(paymentMutations.PAYMENTS_SET_BILLING_ADDRESS).forEach(([key, action]) => {
          if (address.hasOwnProperty(key.toLowerCase())) {
            this[action](address[key.toLowerCase()]);
          }
        });
      },
    },
  },
  methods: {
    ...mapMutations([
      `${paymentMutations.PAYMENTS_SET_CUSTOMER_FIRST_NAME}`,
      `${paymentMutations.PAYMENTS_SET_CUSTOMER_LAST_NAME}`,
      ...Object.values(paymentMutations.PAYMENTS_SET_BILLING_ADDRESS),
    ]),
    /**
     * Generate validation message for each field
     *
     * @param dirty
     * @param validated
     * @param valid
     * @returns {*}
     */
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async validateForm() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return false; // If the return result is false, tab switch is restricted
      }
      return true;
    },
  },
};
</script>

<template>
  <ValidationObserver ref="observer">
    <div class="row">
      <div class="col">
        <div class="row">
          <h2 class="col-md-12">Billing Address</h2>
        </div>

        <div class="row pb-2">
          <div class="col-md">
            <h5>
              <label for="first-name"> First Name: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider
              name="first name"
              rules="required|alpha_spaces"
              v-slot="validationContext"
              :immediate="triggerValidation"
            >
              <b-form-input
                v-model="customerFirstName"
                id="first-name"
                class="form-control"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="first-name-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label for="last-name"> Last Name: </label>
            </h5>
          </div>

          <div class="col-md-9">
            <ValidationProvider
              name="last name"
              rules="required|alpha_spaces"
              v-slot="validationContext"
              :immediate="triggerValidation"
            >
              <b-form-input
                v-model="customerLastName"
                id="last-name"
                class="form-control"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback data-error-name="last-name-error"
                >{{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </div>
        </div>

        <div class="row pb-2">
          <div class="col-md-3">
            <h5>
              <label> Address: </label>
            </h5>
          </div>
        </div>

        <address-lookup :addResidency="false" v-model="billingAddress"></address-lookup>
      </div>
    </div>
  </ValidationObserver>
</template>
