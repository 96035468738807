class Session {
  constructor({ expiry, merchantSessionKey }) {
    this.expires = new Date(expiry);
    this.merchantSessionKey = merchantSessionKey;
  }

  isExpired() {
    return new Date() > this.expires;
  }

  get key() {
    return this.merchantSessionKey;
  }
}

export default Session;
