import axios from 'axios';
import { request, requestError } from '@/plugins/auth/awsAxiosInterceptor';
import MerchantSession from './api/MerchantSession';
import Transaction from './api/Transaction';
import ThreeDSecure from './api/ThreeDSecure';
import PaymentDetails from './api/PaymentDetails';

class SagePay {
  client;

  constructor() {
    this.client = axios.create({ baseURL: process.env.VUE_APP_SAGEPAY_ENDPOINT });
    this.client.interceptors.request.use(request, requestError);
  }

  get merchantSessionApi() {
    return new MerchantSession(this.client);
  }

  get paymentDetailsApi() {
    return new PaymentDetails(this.client);
  }

  get threeDSecureApi() {
    return new ThreeDSecure(this.client);
  }

  get transactionApi() {
    return new Transaction(this.client);
  }
}

export default SagePay;
