import axios from 'axios';
import Api from './Api';

class PaymentDetails extends Api {
  async find(id) {
    try {
      const result = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/cases/find-by-entity-id/${id}`);
      return {
        total: {
          amount: result.data.config.fees.gross.amount,
          currency: 'GBP',
        },
      };
    } catch (e) {
      return undefined;
    }
  }
}

export default PaymentDetails;
