<script>
import { mapGetters } from 'vuex';

export default {
  name: 'IframeCheckout',
  computed: {
    ...mapGetters({ currentCase: 'paymentDetails/case' }),
  },
  props: {
    iframeUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      entityType: '',
      entityId: '',
    };
  },
  async mounted() {
    this.entityId = this.$route.params.entityId;
    this.entityType = this.$route.params.entityType;
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="row">
          <h3 class="col-md-6 d-inline-block">
            Please pay total of: <b>&pound;{{ currentCase.amount / 100 }}</b>
          </h3>
        </div>

        <div class="row w-100 my-2">
          <div class="col-md-8">
            <b-card border-variant="secondary" header="" header-border-variant="primary">
              <b-card-text>
                <iframe :src="iframeUrl" height="500" width="100%" scrolling="no"></iframe>
              </b-card-text>
            </b-card>
          </div>
          <div class="col-md-4">
            <b-card title="Billing Address" border-variant="secondary">
              <b-card-text>
                <ul class="list-unstyled">
                  <li class="w-100 py-1">
                    <h6 class="col-md-6 d-inline-block">Name:</h6>
                    <span class="col-md-6 d-inline-block"
                      >{{ currentCase.customerFirstName }} {{ currentCase.customerLastName }}</span
                    >
                  </li>
                  <li class="w-100 py-1" v-if="currentCase.billingAddress.address1">
                    <h6 class="col-md-6 d-inline-block">Address Line 1:</h6>
                    <span class="col-md-6 d-inline-block">{{ currentCase.billingAddress.address1 }}</span>
                  </li>
                  <li class="w-100 py-1" v-if="currentCase.billingAddress.address2">
                    <h6 class="col-md-6 d-inline-block">Address Line 2:</h6>
                    <span class="col-md-6 d-inline-block">{{ currentCase.billingAddress.address2 }}</span>
                  </li>
                  <li class="w-100 py-1" v-if="currentCase.billingAddress.city">
                    <h6 class="col-md-6 d-inline-block">Town/City:</h6>
                    <span class="col-md-6 d-inline-block">{{ currentCase.billingAddress.city }}</span>
                  </li>
                  <li class="w-100 py-1" v-if="currentCase.billingAddress.postalCode">
                    <h6 class="col-md-6 d-inline-block">Postcode:</h6>
                    <span class="col-md-6 d-inline-block">{{ currentCase.billingAddress.postalCode }}</span>
                  </li>
                  <li class="w-100 py-1" v-if="currentCase.billingAddress.country">
                    <h6 class="col-md-6 d-inline-block">Country:</h6>
                    <span class="col-md-6 d-inline-block">{{ currentCase.billingAddress.country }}</span>
                  </li>
                </ul>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
iframe {
  border: none;
}

iframe > body {
  background: none;
}
</style>
