<script>
import axios from 'axios';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import getSale from '@/api/sales/getSale';
import getPurchase from '@/api/purchases/getPurchase';
import ClientDetails from '@/components/sagepay/ClientDetails';
import PaymentLogos from '@/components/sagepay/PaymentLogos';
import * as paymentMutations from '@/store/payment/mutations';
import { mapGetters, mapMutations, mapState } from 'vuex';
import SagePay from '@/api/sagepay/SagePay';
import IframeCheckout from '../../components/sagepay/IframeCheckout.vue';

export default {
  name: 'Payments',
  props: ['entityType', 'entityId'],
  components: {
    ClientDetails,
    FormWizard,
    TabContent,
    WizardButton,
    PaymentLogos,
    IframeCheckout,
  },
  computed: {
    ...mapGetters({ currentCase: 'paymentDetails/case' }),
    ...mapState({
      s_currentCase: (state) => state.paymentDetails.currentCase,
    }),
    paymentAmount: {
      get() {
        return this.currentCase.amount;
      },
      set(value) {
        this[`${paymentMutations.PAYMENTS_SET_PAYMENT_AMOUNT}`](value);
      },
    },
    caseId: {
      get() {
        return this.s_currentCase;
      },
      set(value) {
        this[`${paymentMutations.PAYMENTS_SET_CURRENT_CASE}`](value);
      },
    },
  },
  data: function () {
    return {
      iframeUrl: false,
      entity: null,
      saving: false,
    };
  },
  methods: {
    ...mapMutations([
      `${paymentMutations.PAYMENTS_SET_INDEX}`,
      `${paymentMutations.PAYMENTS_SET_CURRENT_CASE}`,
      `${paymentMutations.PAYMENTS_SET_PAYMENT_AMOUNT}`,
    ]),
    async ascertainCost() {
      const sagePay = new SagePay();
      const value = await sagePay.paymentDetailsApi.find(this.entityId);
      if (!value) {
        console.error('Attempted to fetch an unknown entity type');
        return;
      }
      return value.total.amount;
    },
    async saveCheckoutDetails() {
      let isValid = await this.$refs.clientDetails.validateForm();
      if (isValid) {
        this.saving = true;
        this.iframeUrl = await axios
          .post(process.env.VUE_APP_SAGEPAY_SERVER_ENDPOINT, {
            entityId: this.entityId,
            entityType: this.entityType,
            amount: this.currentCase.amount / 100,
            firstNames: this.currentCase.customerFirstName,
            surname: this.currentCase.customerLastName,
            address1: this.currentCase.billingAddress.address1,
            city: this.currentCase.billingAddress.city,
            postcode: this.currentCase.billingAddress.postalCode,
            country: 'GB',
            config: this.entity.config,
          })
          .then((res) => res.data.body.data.NextURL);
        this.saving = false;
        this.$refs.paymentWizard.nextTab();
      } else {
        return false;
      }
    },
  },
  async created() {
    this.caseId = this.entityId;
  },
  async mounted() {
    let axiosPromise;
    switch (this.entityType) {
      case 'sale':
        axiosPromise = getSale(this.entityId);
        break;
      case 'purchase':
        axiosPromise = getPurchase(this.entityId);
        break;
    }
    this.entity = await axiosPromise.then((res) => res.data).catch((err) => console.error(err));
    this.paymentAmount = await this.ascertainCost();
  },
};
</script>

<template>
  <div v-if="entity" class="container card card-body section-light">
    <form-wizard
      ref="paymentWizard"
      class="horizontal-tabs"
      :color="$t('site.formColour')"
      stepSize="sm"
      subtitle=""
      title=""
    >
      <tab-content icon="icon-home2" title="Billing Address:">
        <client-details ref="clientDetails" />
      </tab-content>
      <tab-content icon="icon-cart" title="Checkout">
        <iframe-checkout v-if="iframeUrl" :iframeUrl="iframeUrl" />
        <payment-logos />
      </tab-content>
      <!-- Customised Buttons -->
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex == 1"
            :style="props.fillButtonStyle"
            class="w-100 w-sm-auto bg-light border-dashed text-primary"
            @click.native="props.prevTab()"
          >
            <i class="icon-arrow-left5"></i> Change Billing Address
          </wizard-button>
        </div>
        <div class="wizard-footer-right">
          <div v-if="props.activeTabIndex == 0">
            <wizard-button
              :style="props.fillButtonStyle"
              class="wizard-footer-right w-100 w-sm-auto"
              @click.native="saveCheckoutDetails"
            >
              Checkout
              <i v-if="saving" class="spinner icon-spinner2 ml-2"></i>
              <i v-else class="icon-arrow-right5 ml-2"></i>
            </wizard-button>
          </div>
        </div>
      </template>
    </form-wizard>
  </div>
</template>
