import TransactionResult from './transaction/TransactionResult';
import Api from './Api';

class Transaction extends Api {
  async create(paymentDetails, cardIdentifier, merchantSessionKey, notificationUrl) {
    const payload = await this.createPayload(paymentDetails, cardIdentifier, merchantSessionKey, notificationUrl);
    return (await this.client.post('/transactions', payload)).data;
  }

  createPayload(paymentDetails, cardIdentifier, merchantSessionKey, notificationUrl) {
    return {
      ...JSON.parse(JSON.stringify(paymentDetails)),
      paymentMethod: {
        card: {
          cardIdentifier: cardIdentifier,
          merchantSessionKey: merchantSessionKey,
        },
      },
      strongCustomerAuthentication: {
        browserColorDepth: screen.colorDepth,
        browserJavaEnabled: navigator.javaEnabled(),
        browserLanguage: navigator.language,
        browserScreenHeight: screen.height,
        browserScreenWidth: screen.width,
        browserTZ: new Date().getTimezoneOffset(),
        browserUserAgent: navigator.userAgent,
        challengeWindowSize: 'Small',
        notificationUrl: notificationUrl,
      },
    };
  }

  async find(id) {
    try {
      const result = (await this.client.get(`/transactions/${id}`)).data;
      return new TransactionResult(result);
    } catch (e) {
      return new TransactionResult();
    }
  }
}

export default Transaction;
