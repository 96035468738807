class TransactionResult {
  result;

  constructor(result = {}) {
    this.result = result;
  }

  isSuccessful() {
    return this.result.statusCode === '0000';
  }
}

export default TransactionResult;
