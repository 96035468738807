<script>
export default {
  name: 'PaymentLogos',
  data() {
    return {
      mainProps: { height: 50, class: 'm-1' },
      images: {
        sagepay: require('@/assets/sagepay_logo.jpg'),
        mastercard: require('@/assets/mastercard_logo.png'),
        visa: require('@/assets/visa_logo.jpg'),
      },
    };
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-2">
        <h5 class="text-center text-sm-left">
          <label> We accept: </label>
        </h5>
      </div>

      <div class="col-6 col-sm-2 text-center text-sm-right">
        <b-img v-bind="mainProps" :src="images.mastercard" alt=""></b-img>
      </div>

      <div class="col-6 col-sm-2 text-center text-sm-right">
        <b-img v-bind="mainProps" :src="images.visa" alt=""></b-img>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12 text-center">
        <b-img v-bind="mainProps" :src="images.sagepay" alt=""></b-img>
      </div>
    </div>
  </div>
</template>
