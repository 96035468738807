<script>
export default {
  name: 'Loader',
};
</script>

<template>
  <div class="text-center m-3">
    <div class="align-self-center text-muted">
      <slot></slot>
    </div>
    <div><i class="spinner-border text-muted mt-2"></i></div>
  </div>
</template>
